import { useLoadScript } from '@react-google-maps/api'
import Layout from 'Layout'
import { MappaPrincipale } from 'components/Mappa/MappaPrincipale'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { LayoutContextProvider } from 'contexts/LayoutContext'
import { graphql } from 'gatsby'
import { useA2AStoreQuery } from 'hooks/use-a2a-store-query'
import { Helmet } from 'react-helmet'
import { capitalizeFirstLetterWords } from '../../../commons/utils'
import { useEffect, useState } from 'react'

const StoreProvinciaPage = (props: A2AStorePageProps) => {
  const { a2AStore } = props.data
  const a2aStores = useA2AStoreQuery()
  const [provinceData, setProvinceData] = useState<{
    city: string
    description: string
  }>()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_KEY || '',
    libraries: ['places'],
  })

  useEffect(() => {
    if (
      a2AStore &&
      a2AStore.mapData &&
      a2AStore.mapData.detailsMapsCity &&
      a2AStore.provincia
    ) {
      const cityData = a2AStore.mapData.detailsMapsCity.find((item) => {
        // item.name example: "milano", "monza e della brianza"
        const provinceNameArray = item?.name
          ?.toLowerCase()
          .split(' ')
          .filter((element) => element.length > 1)
        if (provinceNameArray.length > 1) {
          return a2AStore.provincia
            .toLowerCase()
            .split(' ')
            .every((element) => provinceNameArray.includes(element))
        } else {
          return String(a2AStore.provincia.toLowerCase())
            .split(' ')
            .includes(String(provinceNameArray[0]).toLowerCase())
        }
      })
      if (cityData)
        setProvinceData({
          city: cityData.name,
          description: cityData.sottotitolo,
        })
    }
  }, [a2AStore])

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <Layout
          pageTitle={`Spazio A2A e Sportelli a ${capitalizeFirstLetterWords(
            a2AStore.provincia
          )} | A2A Energia`}
          footerType={'simple'}
        >
          <Helmet>
            <meta
              name="description"
              content={
                provinceData
                  ? provinceData.description
                  : `Scopri gli Spazi A2A e i nostri Sportelli commerciali più vicino a te. Consulta la mappa e tutti i dettagli relativi agli store presenti a ${capitalizeFirstLetterWords(
                      a2AStore.provincia
                    )}.`
              }
            />
          </Helmet>
          <MappaPrincipale
            isLoaded={isLoaded}
            province={a2AStore.provincia}
            stores={a2aStores}
            mapData={a2AStore.mapData}
            isInMapPage={true}
          />
        </Layout>
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default StoreProvinciaPage

interface A2AStorePageProps {
  data: {
    a2AStore: {
      provincia: string
      mapData: {
        detailsPageMaps: {
          titolo: string
          sottotitolo: string
        }
        detailsMapsCity: {
          name: string
          sottotitolo: string
        }[]
      }
    }
  }
}

export const query = graphql`
  query ($id: String!) {
    a2AStore(id: { eq: $id }) {
      provincia
      city
      mapData {
        detailsPageMaps {
          titolo
          sottotitolo
        }
        detailsMapsCity {
          name
          sottotitolo
        }
      }
    }
  }
`
